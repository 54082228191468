/*------------------*
# Header
*------------------*/

header {
    position: fixed;
    width: 100%;
    z-index: 1;
    background: black;
    transition: 150ms ease-in-out all;
    padding-bottom: 10px;
    @include media-breakpoint-up(md) {
        background: rgba($color: #000000, $alpha: 0);
        padding-bottom: 0px;
    }
    &.active {
        background: rgba($color: #000000, $alpha: 1);
        .logo {
            opacity: 1;
        }
    }

    .header-flex {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }
    
    .logo {
        margin-top: 12px;
        opacity: 1;
        transition: opacity ease-in-out 200ms;
        @include media-breakpoint-up(md) {
            opacity: 0;
        }
        a {
            text-align: center;
            display: block;
            img {
                width: 50px;
                @include media-breakpoint-up(md) {
                    width: 90px;
                }
            }
            @include media-breakpoint-up(md) {
                text-align: initial;
            }
        }
    }
    
    nav {
        padding-top: 10px;
        @include media-breakpoint-up(md) {
            padding-top: 50px;
        }
        ul {
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;
            justify-content: space-between;
            @include media-breakpoint-up(md) {
                justify-content: flex-end;
            }
            li {
                &:nth-of-type(1) {
                    margin: 0;
                }
                @include media-breakpoint-up(md) {
                    margin-left: 40px;
                }
                a {
                    font-family: $base-font-family-medium;
                    color: $color-primary;
                    text-transform: uppercase;
                    text-decoration: none;
                    letter-spacing: 2px;
                    font-size: 13px;
                    
                }
            }
        }
    }
}



.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 5%;
    background: black;
    height: 50px;
    width: 50px;
    padding: 10px;
    display: none;
    border: 1px solid $color-primary;
    z-index: 1;
    border-radius: 50%;
    opacity: 0.3;
    transition: 300ms ease-in-out opacity;
    img {
        display: block;
        transform: rotate(-90deg);
    }
    &:hover {
        opacity: 1;
    }
}

.bouncing-arrow {
    position: absolute;
    bottom: 60px;
    right: 5%;
    width: 25px;
    animation: bounce 3s infinite ease-in-out;
}

@-webkit-keyframes bounce {
    0% { transform: translateY(-5px)  }
    50% { transform: translateY(10px) }
    100% { transform: translateY(-5px) }
}

@keyframes bounce {
    0% { transform: translateY(-5px)  }
    50% { transform: translateY(10px) }
    100% { transform: translateY(-5px) }
}