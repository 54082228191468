/*------------------*
# Headings
*------------------*/

/*
 * Uniform spacing and color for headings
*/

.h1 {
  font-family: $base-font-family-bold;
  font-size: 30px;
  line-height: 1;
  padding: 40px 0 0 0;
  margin: 0 0 40px 0;
  @include media-breakpoint-up(sm) {
    font-size: 30px;
  }
  @include media-breakpoint-up(md) {
    font-size: 35px;
  }
}

h1 {
  @extend .h1;
}

.h2 {
  font-family: $base-font-family-bold;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1;
  padding: 0px 0 0 0;
  margin: 0 0 15px 0;
  letter-spacing: 2px;
  @include media-breakpoint-up(md) {
    font-size: 17px;
    letter-spacing: 3px;
    margin: 0;
  }
}

h2 {
  @extend .h2;
}

.h3 {
  font-family: $base-font-family-bold;
  font-size: 70px;
  line-height: 1;
  padding: 0px 0 0 0;
  margin: 0;
  @include media-breakpoint-up(sm) {
    font-size: 50px;
  }
  @include media-breakpoint-up(md) {
    font-size: 80px;
  }
}

h3 {
  @extend .h3;
}

.h4 {
  font-family: $base-font-family;
  font-size: 22px;
  line-height: 1.4;
  padding: 0px 0 0 0;
  text-transform: none;
  margin: 0;
  letter-spacing: 0px;
  font-weight: 100;
}

h4 {
  @extend .h4;
}

.h5 {
  font-family: $base-font-family-bold;
  line-height: 1.4;
  padding: 0px 0 0 0;
  text-transform: none;
  margin: 0;
  margin-bottom: 30px;
  letter-spacing: 0px;
  font-weight: 100;
  font-size: 24px;
  margin-bottom: 20px;
}

h5 {
  @extend .h5;
}

.p {
  font-family: $base-font-family;
  font-size: 16px;
  line-height: 28px;
  padding: 0;
  margin: 0 0 30px 0;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  &-small {
    font-size: 16px;
    line-height: 1.4;
  }
  &-footer {
    font-family: $base-font-family-medium;
    font-size: 13px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  a {
    color: $color-primary;
  }
}

p {
  @extend .p;
}

.font-bold {
  font-family: $base-font-family-bold;
}
