.client {
    &-image {
        img {
            width: 100%;
            display: block;
            @include media-breakpoint-up(md) {
                width: 70%;
                margin: 0 auto;
            }
        }
    }
    &-content { 
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
        @include media-breakpoint-up(md) {
            margin-bottom: 50px;
        }
    }
}

.scattered-image {
    img {
        display: block;
        width: 100%;
    }
    .row1 {
        display: flex;
        position: relative;
        justify-content: space-between;
        margin-bottom: 5vw;
        .pic1 {
            margin-left: 0vw;
            img {
                width: 20vw;
            }
        }
        .pic2 {
            margin-top: 5vw;
            img {
                width: 30vw;
            }
        }
        .pic3 {
            margin-right: 4vw;
            margin-top: 2vw;
            img {
                width: 20vw;
            }
        }
    }
    .row2 {
        display: flex;
        position: relative;
        justify-content: space-between;
        margin-bottom: 5vw;
        .pic1 {
            margin-top: 5vw;
            margin-left: 15vw;
            img {
                width: 27vw;
            }
        }
        .pic2 {
            margin-right: 20vw;
            img {
                width: 20vw;
            }
        }
    }
    .row3 {
        display: flex;
        position: relative;
        justify-content: space-between;
        margin-bottom: 5vw;
        .pic1 {
            margin-left: 5vw;
            img {
                width: 20vw;
            }
        }
        .pic2 {
            margin-top: 5vw;
            img {
                width: 30vw;
            }
        }
        .pic3 {
            margin-right: 10vw;
            margin-top: 2vw;
            img {
                width: 20vw;
            }
        }
    }
    .row4 {
        display: flex;
        position: relative;
        justify-content: space-between;
        margin-bottom: 5vw;
        .pic1 {
            margin-top: 5vw;
            margin-left: 12vw;
            img {
                width: 37vw;
            }
        }
        .pic2 {
            margin-right: 12vw;
            img {
                width: 25vw;
            }
        }
    }
    .row5 {
        display: flex;
        position: relative;
        justify-content: space-between;
        .pic1 {
            img {
                width: 25vw;
            }
        }
        .pic2 {
            margin-top: 5vw;
            img {
                width: 30vw;
            }
        }
        .pic3 {
            margin-top: 15vw;
            img {
                width: 30vw;
            }
        }
    }
}

.b1 {
    animation: stroke_fill 2s linear forwards;
    stroke-dasharray: 688;
    stroke-dashoffset: 688;
  }
  
  .step {
    &-1 {
        animation-delay: 0s;
    }
    &-2 {
        animation-delay: 0.1s;
    }
    &-3 {
        animation-delay: 0.2s;
    }
    &-4 {
        animation-delay: 0.3s;
    }
    &-5 {
        animation-delay: 0.4s;
    }
    &-6 {
        animation-delay: 0.5s;
    }
    &-7 {
        animation-delay: 0.6s;
    }
    &-8 {
        animation-delay: 0.7s;
    }
    &-9 {
        animation-delay: 0.8s;
    }
    &-10 {
        animation-delay: 0.9s;
    }
    &-11 {
        animation-delay: 1s;
    }
    &-12 {
        animation-delay: 1.1s;
    }
    &-13 {
        animation-delay: 1.2s;
    }
  }
  
  @keyframes stroke_fill {
    0% {
      fill: transparent;
    }
    50% {
      fill: transparent;
      stroke-dashoffset: 0;
    }
    100% {
      fill: #DFDDD0;
      stroke-dashoffset: 0;
    }
  }