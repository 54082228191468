footer {
    padding: 20px 0;
    p {
        @extend .p-footer;
        margin: 0;
    }
    .footer-left {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        @include media-breakpoint-up(md) {
            justify-content: initial;
        }
        img {
            width: 50px;
            display: block;
            @include media-breakpoint-up(md) {
                width: 80px;
            }
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            li {
                margin-left: 30px;
                a {
                    text-decoration: none;
                    color: $color-primary;
                }
            }
        }
    }
    .footer-right {
        text-align: center;
        margin-top: 40px;
        @include media-breakpoint-up(md) {
            text-align: right;
            margin-top: 0;
        }
    }
}