.modal {
    position: relative;
    z-index: 999999;
}
  
  .modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal__container {
    background: rgb(4,4,4);
    background: linear-gradient(0deg, rgb(14, 14, 14) 0%, rgb(0, 0, 0) 100%);
    max-height: 90vh;
    width: 80%;
    padding: 30px;
    overflow-y: auto;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px #292929;
  }
  
  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .modal__close {
    background: transparent;
    border: 0;
    color: white;
  }
  
  .modal__header .modal__close:before { content: "\2715"; }
  
  .modal__btn:focus, .modal__btn:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  
  
  
  /**************************\
    Demo Animation Style
  \**************************/
  @keyframes mmfadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
  }
  
  @keyframes mmfadeOut {
      from { opacity: 1; }
        to { opacity: 0; }
  }
  
  @keyframes mmslideIn {
    from { transform: translateY(15%); }
      to { transform: translateY(0); }
  }
  
  @keyframes mmslideOut {
      from { transform: translateY(0); }
      to { transform: translateY(-10%); }
  }
  
  .micromodal-slide {
    display: none;
  }
  
  .micromodal-slide.is-open {
    display: block;
  }
  
  .micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }
  
  .micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }
  
  .micromodal-slide .modal__container,
  .micromodal-slide .modal__overlay {
    will-change: transform;
  }
  