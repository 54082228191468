.accordion {
    margin-top: -20px;
    &--item {
        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $color-primary;
            padding: 13px 0;
            @include media-breakpoint-up(md) {
                padding: 20px 0;
            }
            cursor: pointer;
            .arrow {
                transform: rotate(90deg);
                transition: transform 300ms ease-in-out;
                img {
                    width: 13px;
                    height: 13px;
                    display: block;
                    @include media-breakpoint-up(md) {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            &.active {
                .arrow {
                    transform: rotate(-90deg);
                }
            }
        }
        .content{
            display: none;
            padding: 20px 0 20px 0;
            @include media-breakpoint-up(sm) {
                padding: 20px 0 20px 0;
            }
            @include media-breakpoint-up(md) {
                padding: 30px 0 40px 0;
            }
            p {
                @extend .p-small;
            }
        }
    }
}